import React from "react";

import "./styles.scss";
import {  useLocation } from "@reach/router";

const Card = (props) => {

  const {cardData} = props;

  const location = useLocation();

  const handleNavigation = (url) => {
    if(typeof window !== "undefined") {
      window.location = url + location?.search ?? "";
    }
  };

  return (
    <div className={`program-card-main ${cardData?.isNew ? "new-program-badge" : ""}`}>
      <div className="card-heading">
        {cardData?.heading ?? ""}
      </div>
      <div className="card-description">
        {cardData?.description ?? ""}
      </div>
      <div className="program-duration">
        {cardData?.duration ?? ""}
      </div>
      {/* <Link
        // component="button"
        className="apply-btn"
        to={cardData.applyURL + props.location?.search ?? ""}
      >
              Apply Now
      </Link> */}
      <button className="apply-btn" onClick={()=> handleNavigation(cardData.applyURL)}>
        Apply Now
      </button>
    </div>
  );
};

export default Card;