import React from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import Programs from "../../components/Programs";

const KraftshalaPrograms = (props) => {
  return (
    <>
      <Seo
        title=""
        description=""
        url="https://www.kraftshala.com/programs/"
      />
      <Layout>
        <Programs location={props.location}/>
      </Layout>
    </>
  );
};

export default KraftshalaPrograms;